import { storeToRefs } from 'pinia'

export default defineNuxtRouteMiddleware(() => {
  const customerStore = useCustomerStore()
  const creatorStore = useCreatorStore()

  const { isCreator } = storeToRefs(customerStore)
  const { isPublished } = storeToRefs(creatorStore)
  if (!isCreator.value || !isPublished.value) {
    return navigateTo('/')
  }
})
